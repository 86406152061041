import React from "react";
import Slider from "react-slick";
import Card from "react-bootstrap/Card";

import adquirentes from "./CarouselObjects";

const settings = {
  speed: 1000,
  slidesToShow: 6,
  slidesToScroll: 6,
  rows: 3,
  infinite: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 4,
        rows: 2,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        speed: 800,
        slidesToShow: 6,
        slidesToScroll: 6,
        rows: 2,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        speed: 800,
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: 3,
        swipeToSlide: true,
      },
    },
  ],
};

const CardAdquirente = (props) => {
  return (
    <React.Fragment>
      <Card className="carousel-card">
        <Card.Img variant="top" src={props.image} alt={props.alt} />
      </Card>
    </React.Fragment>
  );
};

export default function Carousel() {
  return (
    <Slider {...settings}>
      {adquirentes.map((i, index) => {
        return <CardAdquirente key={index} image={i.image} alt={i.alt} />;
      })}
    </Slider>
  );
}
