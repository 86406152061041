import React from 'react'
import { Link } from 'react-scroll'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import TelaConciliador from '../../static/tela-statix.svg'
import Background from '../../static/landing-page-background.jpg'

export default function Conciliador(){
    
    const transition = 800
    const offset = -90

    return(
        <Container as="section" fluid id="conciliador" style={{ backgroundImage: `url(${Background})` }}>
            <Row id="conciliador-row">
                <Col id="conciliador-texto" xl="6" md="12" sm="12" lg="6">
                    <h1>conciliador de cartões</h1>
                    <h2>simples, intuitivo e prático</h2>

                    <ul>
                        <li>Elimine fraudes</li>
                        <li>Evite perdas financeiras</li>
                        <li>Otimize a eficiência operacional</li>
                        <li>Efetue baixas automáticas em seu ERP</li>
                    </ul>
                    <Button id="btn-comecar" as={Link} to="contato" smooth={true} duration={transition} offset={offset} variant="light">Comece agora</Button>
                </Col>
                <Col id="conciliador-tela" xl="6" md="12" sm="12" lg="6" style={{ backgroundImage: `url(${TelaConciliador})` }}>
                </Col>
            </Row>
        </Container>
    )
}