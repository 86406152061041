function CieloDeniedResponse() {
  return (
    <div style={{ fontSize: "1rem" }}>
      <h2>O envio dos dados foi recusado</h2> <br />
      <p>
        Você optou por não aceitar o envio dos seus dados. O processo foi
        interrompido.
      </p>
      <p>
        Se isso foi um engano, tente novamente com o mesmo link, e clique no
        botão de aceitar. Se precisar de ajuda ou tiver alguma dúvida, por
        favor, entre em contato com o nosso time de suporte para mais
        informações.
      </p>
      <p>
        Atenciosamente,
        <br />
        Equipe de Suporte
      </p>
    </div>
  );
}

function CieloSuccessResponse() {
  return (
    <div style={{ fontSize: "1rem" }}>
      <h2>Dados liberados com sucesso!</h2> <br />
      <p>
        Obrigado por aceitar o envio dos seus dados. O processo foi concluído
        com sucesso e suas informações foram encaminhadas.
      </p>
      <p>
        Se você tiver qualquer dúvida ou precisar de mais informações, entre em
        contato com o nosso time de suporte.
      </p>
      <p>
        Atenciosamente,
        <br />
        Equipe de Suporte
      </p>
    </div>
  );
}

function CieloErrorReponse({ traceId }) {
  return (
    <div style={{ fontSize: "1rem" }}>
      <h2>Erro inesperado</h2> <br />
      <p>
        Ocorreu um erro inesperado durante o processo de liberação dos seus
        dados. Lamentamos pelo inconveniente.
      </p>
      <p>
        Para que o time de suporte possa te ajudar, informe o código abaixo:{" "}
        <br />
        <br />
        <code>{traceId}</code> <br />
        <br />
      </p>
      <p>
        Atenciosamente,
        <br />
        Equipe de Suporte
      </p>
    </div>
  );
}

export { CieloDeniedResponse, CieloSuccessResponse, CieloErrorReponse };
