import React, { useEffect } from "react";

import "./styles/main.sass";
import Header from "./components/header/Header";
// import LandingPage from './views/landing-page/LandingPage'
import Conciliador from "./views/conciliador/Conciliador";
import Adquirentes from "./views/adquirentes-integradas/Adquirentes";
import Contato from "./views/contato/Contato";
import QuemSomos from "./views/quem-somos/QuemSomos";
import Servicos from "./views/servicos/Servicos";
import SejaParceiro from "./views/seja-parceiro/SejaParceiro";
import AtuacaoNacional from "./views/atuacao-nacional/AtuacaoNacional";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  CieloDeniedResponse,
  CieloErrorReponse,
  CieloSuccessResponse,
} from "./components/oauth-redirect-alert/cielo-response";

function App() {
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const redirectOauth = queryParams.get("redirect_oauth");
    const acesso = queryParams.get("acesso");
    const traceId = queryParams.get("traceID");

    if (!redirectOauth) return;

    const alertConfig = {
      erro_inesperado: {
        icon: "error",
        title: <CieloErrorReponse traceId={traceId} />,
      },
      ok: {
        icon: "success",
        iconHtml: "✔",
        title: <CieloSuccessResponse />,
      },
      nao_autorizado: {
        icon: "warning",
        title: <CieloDeniedResponse />,
      },
    };

    const config = alertConfig[acesso] || alertConfig.erro_inesperado;

    MySwal.fire({
      ...config,
      confirmButtonColor: "#4080c7",
      confirmButtonText: "Fechar",
    });
  }, [MySwal]);

  return (
    <React.Fragment>
      <Header />
      {/* <LandingPage/>   */}
      <Conciliador />
      <Adquirentes />
      <QuemSomos />
      <Servicos />
      <AtuacaoNacional />
      <SejaParceiro />
      <Contato />
    </React.Fragment>
  );
}

export default App;
