/* eslint-disable no-useless-escape */
import * as Yup from 'yup'

export const FormSchema = Yup.object().shape({
    nome: Yup.string()
        .min(3, 'Insira um nome entre 3 e 50 caracteres.')
        .max(50, 'Insira um nome entre 3 e 50 caracteres.')
        .required('Campo obrigatório'),
    area: Yup.string()
        .min(3, 'Insira uma área de atuação entre 3 e 20 caracteres.')
        .max(20, 'Insira uma área de atuação entre 3 e 20 caracteres.')
        .required('Campo obrigatório'),
    email: Yup.string()
        .email('Insira um endereço de e-mail válido')
        .required('Campo obrigatório'),
    telefone: Yup.string()
        .min(10, 'Insira um número de telefone válido.')
        .matches(/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/, 'Insira um número de telefone válido.')
        .required('Campo obrigatório'),
    razaoSocial: Yup.string()
        .min(5, 'Insira uma razão social válida.')
        .required('Campo obrigatório')
})

export const FormSchemaOriginal = Yup.object().shape({
    nome: Yup.string()
        .min(3, 'Insira um nome entre 3 e 50 caracteres.')
        .max(50, 'Insira um nome entre 3 e 50 caracteres.')
        .required('Campo obrigatório'),
    codigo: Yup.string()
        .min(11, 'Insira um CNPJ/CPF válido.')
        .max(18, 'Insira um CNPJ/CPF válido.')
        .required('Campo obrigatório'),
    area: Yup.string()
        .min(3, 'Insira uma área de atuação entre 3 e 20 caracteres.')
        .max(20, 'Insira uma área de atuação entre 3 e 20 caracteres.')
        .required('Campo obrigatório'),
    email: Yup.string()
        .email('Insira um endereço de e-mail válido')
        .required('Campo obrigatório'),
    telefone: Yup.string()
        .min(10, 'Insira um número de telefone válido.')
        .matches(/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/, 'Insira um número de telefone válido.')
        .required('Campo obrigatório'),
    endereco: Yup.string()
        .min(10, 'Insira um endereço entre 10 e 60 caracteres.')
        .max(60, 'Insira um endereço entre 10 e 60 caracteres.')
        .required('Campo obrigatório'),
    cidade: Yup.string()
        .min(3, 'Insira uma cidade entre 3 e 20 caracteres.')
        .max(20, 'Insira uma cidade entre 3 e 20 caracteres.')
        .required('Campo obrigatório'),
    cep: Yup.string()
        .matches(/^\d{5}-\d{3}$/)
        .required('Campo obrigatório'),
    estado: Yup.string()
        .required('')
})