import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import Image from '../../static/mapa-brasil.png'

export default function AtuacaoNacional(){
    return (
        <Container fluid as="section" id="atuacao-nacional">
            <Row>
                <Col xl="6" md="12" sm="12" lg="6">
                    <h1>atuação nacional</h1>
                    <p>
                        Atendemos em todo o Brasil através de nossos colaboradores e parceiros. 
                        Junte-se a nós e usufrua da melhor ferramenta de conciliação do mercado 
                        com o atendimento mais humanizado para seus recebíveis.
                    </p>
                </Col>
                <Col xl="6" md="12" sm="12" lg="6" id="col-img">
                    <Card id="mapa-card">
                        <Card.Img src={Image} alt="Mapa do Brasil"/>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}