import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import ImgParceiro from '../../static/parceiros-img.jpg'

export default function SejaParceiro(){
    return (
        <Container fluid id="parceiros">
            <Row id="parceiro-row">
                <Col id="parceiro-img" xl="6" md="12" sm="12" lg="6">
                    <Card>
                        <Card.Img src={ImgParceiro} alt="" id="seja-parceiro-img" />
                    </Card>
                </Col> 
                <Col xl="6" md="12" sm="12" lg="6" id="parceiro-text">
                    <h1>seja nosso parceiro</h1>
                    <ul>
                        <li>Atendimento exclusivo</li>
                        <li>Solução robusta, eficiente e de extrema competitividade do mercado</li>
                        <li>Aumento no mix de produtos do seu negócio</li>
                        <li>White Label</li>
                        <li>Receita recorrente</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    )
}