import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'

import Image1 from '../../static/bg-servicos/conciliacao-integrada.jpg'
import Image2 from '../../static/bg-servicos/biro-conciliacao.jpg'
import Image3 from '../../static/bg-servicos/recorrencia-financeira.jpg'
import Col from 'react-bootstrap/Col'

const servicesList = [
    { image: Image1, title: "conciliação integrada" },
    { image: Image2, title: "birô de conciliação" },
    { image: Image3, title: "recorrência financeira" }
]

export default function Servicos(){
    return (
        <Container fluid as="section" id="servicos">
            <Row>
                <Col>
                    <h1>serviços</h1>   
                </Col>
            </Row>
            <Row xl="4" md="12" sm="12" lg="4" id="service-row">
                {servicesList.map((i, index) => {
                    return (
                        <Card key={index} className="service-card" id={`service-card-${index + 1}`}>
                            <Card.Img src={i.image} alt={`Serviços da Statix: ${i.title}`}/>
                            <Card.ImgOverlay>
                                <Card.Title as="h2" className="text-center">{i.title}</Card.Title>
                            </Card.ImgOverlay>
                        </Card>
                    )
                })}
            </Row>
        </Container>
    )
}