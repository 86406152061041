import LogoBanese from '../../static/logo-adquirentes/logo-banese.png'
import LogoBanpara from '../../static/logo-adquirentes/logo-banpara.png'
import LogoBenVisaVale from '../../static/logo-adquirentes/logo-benvisavale.png'
import LogoBin from '../../static/logo-adquirentes/logo-bin.png'
import LogoBiq from '../../static/logo-adquirentes/logo-biq.png'
import LogoBnb from '../../static/logo-adquirentes/logo-bnb.png'
import LogoBrasilCard from '../../static/logo-adquirentes/logo-brasilcard.png'
import LogoCielo from '../../static/logo-adquirentes/logo-cielo.png'
import LogoCredishop from '../../static/logo-adquirentes/logo-credishop.png'
import LogoDacasa from '../../static/logo-adquirentes/logo-dacasa.png'
import LogoFortbrasil from '../../static/logo-adquirentes/logo-fortbrasil.png'
import LogoFancard from '../../static/logo-adquirentes/logo-fancard.png'
import LogoGetnet from '../../static/logo-adquirentes/logo-getnet.png'
import LogoGoodcard from '../../static/logo-adquirentes/logo-goodcard.png'
import LogoGreencard from '../../static/logo-adquirentes/logo-greencard.png'
import LogoLibercard from '../../static/logo-adquirentes/logo-libercard.png'
import LogoLosango from '../../static/logo-adquirentes/logo-losango.png'
import LogoMaxx from '../../static/logo-adquirentes/logo-maxx.png'
import LogoNutricash from '../../static/logo-adquirentes/logo-nutricash.png'
import LogoPagseguro from '../../static/logo-adquirentes/logo-pagseguro.png'
import LogoPlanvale from '../../static/logo-adquirentes/logo-planvale.png'
import LogoPolicard from '../../static/logo-adquirentes/logo-policard.png'
import LogoRede from '../../static/logo-adquirentes/logo-rede.png'
import LogoSafra from '../../static/logo-adquirentes/logo-safra.png'
import LogoSenff from '../../static/logo-adquirentes/logo-senff.png'
import LogoSicredi from '../../static/logo-adquirentes/logo-sicredi.png'
import LogoSodexo from '../../static/logo-adquirentes/logo-sodexo.png'
import LogoSoma from '../../static/logo-adquirentes/logo-soma.png'
import LogoStone from '../../static/logo-adquirentes/logo-stone.png'
import LogoSysprocard from '../../static/logo-adquirentes/logo-sysprocard.png'
import LogoTicket from '../../static/logo-adquirentes/logo-ticket.png'
import LogoTricard from '../../static/logo-adquirentes/logo-tricard.png'
import LogoUp from '../../static/logo-adquirentes/logo-up.png'
import LogoUze from '../../static/logo-adquirentes/logo-uze.png'
import LogoValecard from '../../static/logo-adquirentes/logo-valecard.png'
import LogoValemais from '../../static/logo-adquirentes/logo-valemais.png'
import LogoValeshop from '../../static/logo-adquirentes/logo-valeshop.png'
import LogoVegas from '../../static/logo-adquirentes/logo-vegas.png'
import LogoVero from '../../static/logo-adquirentes/logo-vero.png'
import LogoVr from '../../static/logo-adquirentes/logo-vr.png'

const adquirentes = [
    { image: LogoRede, alt: 'Rede' },
    { image: LogoCielo, alt: 'Cielo' },
    { image: LogoGetnet, alt: 'Getnet' },
    { image: LogoStone, alt: 'Stone' },
    { image: LogoBin, alt: 'Bin' },
    { image: LogoPagseguro, alt: 'PagSeguro' },
    { image: LogoVero, alt: 'Vero' },
    { image: LogoSafra, alt: 'Safra' },
    { image: LogoSicredi, alt: 'Sicredi' },
    { image: LogoTicket, alt: 'Ticket' },
    { image: LogoVr, alt: 'VR Benefícios' },
    { image: LogoSodexo, alt: 'Sodexo' },
    { image: LogoCredishop, alt: 'Credishop' },
    { image: LogoValecard, alt: 'ValeCard' },
    { image: LogoTricard, alt: 'Tricard' },
    { image: LogoLibercard, alt: 'LiberCard' },
    { image: LogoUze, alt: 'Uze' },
    { image: LogoFortbrasil, alt: 'Fortbrasil' },
    { image: LogoGoodcard, alt: 'GoodCard' },
    { image: LogoBanese, alt: 'Banese' },
    { image: LogoBiq, alt: 'BIQ' },
    { image: LogoSoma, alt: 'Soma' },
    { image: LogoBanpara, alt: 'Banpara' },
    { image: LogoBenVisaVale, alt: 'BenVisaVale' },
    { image: LogoBnb, alt: 'BNB' },
    { image: LogoBrasilCard, alt: 'BrasilCard' },
    { image: LogoDacasa, alt: 'Dacasa' },
    { image: LogoMaxx, alt: 'Maxx' },
    { image: LogoGreencard, alt: 'GreenCard' },
    { image: LogoLosango, alt: 'Losango' },
    { image: LogoNutricash, alt: 'Nutricash' },
    { image: LogoPolicard, alt: 'PoliCard' },
    { image: LogoPlanvale, alt: 'Planvale' },
    { image: LogoSenff, alt: 'Senff' },
    { image: LogoFancard, alt: 'FanCard' },
    { image: LogoSysprocard, alt: 'SysproCard' },
    { image: LogoUp, alt: 'Up' },
    { image: LogoVegas, alt: 'VegasCard' },
    { image: LogoValeshop, alt: 'ValeShop' },
    { image: LogoValemais, alt: 'Valemais' },

]

export default adquirentes