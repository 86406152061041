import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Formulario from "../formulario/Formulario";

const mapURL =
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15924.876483172122!2d-38.4817128!3d-3.7624273!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x12a3f1128f15118a!2sSalinas%20Shopping!5e0!3m2!1spt-BR!2sbr!4v1611754715262!5m2!1spt-BR!2sbr";

export default function Contato() {
  return (
    <React.Fragment>
      <Container fluid as="section" id="contato">
        <Row id="contato-row">
          <Col xl="6" md="12" sm="12" lg="6" id="contato-text">
            <h1>contato</h1>
            <h3>Av. Washington Soares, 909</h3>
            <h3>Edson Queiroz, Fortaleza - CE</h3>
            <h3>Salinas Shopping</h3>
            <h3>Bloco D, Salas 53 e 54</h3>
            <h3 id="telefone">(85) 3036-2270</h3>
          </Col>
          <Col id="col-formulario" xl="6" md="12" sm="12" lg="6">
            <Formulario />
          </Col>
        </Row>
        <Row xl={12} sm={12} id="row-mapa">
          <iframe
            id="mapa-statix"
            title="Endereço Statix"
            src={mapURL}
            frameBorder="0"
            aria-hidden="false"
            tabIndex="0"
          />
        </Row>
        <Row xl={12} sm={12} id="copyright">
          <h6>{`Copyright ${String.fromCharCode(
            0x00a9
          )} ${new Date().getFullYear()} Statix Inovação e Tecnologia`}</h6>
        </Row>
      </Container>
    </React.Fragment>
  );
}
