import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export default function ModalFeedback(props){
    return (
        <Modal {...props} size="lg" centered>
            <Modal.Body id="modal-body">
                <h1>Dados enviados com sucesso!</h1>
                <p>
                    Agradecemos por escolher a Statix! Muito em breve nossa equipe comercial 
                    entrará em contato, e você fará parte do nosso time. Por isso, fique atento à 
                    sua caixa de entrada e ao telefone. Até daqui a pouco!
                </p>
            </Modal.Body>
            <Modal.Footer id="modal-footer">
                <Button id="btn-close" onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}